<template>
    <div>
        <div class="row">
            <div class="col-md hCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h2 class="mar_0">
                            ₦ 20,000,000 
                        </h2>
                        <br/>
                        <h3 class="mar_0">Total Sales</h3>
                    </div>
                    <div class="col-3 text-center">
                        <!-- <img width="70px" height="70px" src="../../../"/> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4 hCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h2 class="mar_0">
                            23,000 
                        </h2>
                        <br/>
                        <h3 class="mar_0">Total Product | Iventory</h3>
                    </div>
                    <div class="col-3 pad_0 text-center">
                        <!-- <img width="70px" height="70px" src="../../../../svg/inventory.svg"/> -->
                    </div>
                </div>
            </div> 
            <div class="col-md-4 hCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <br/>
                        <h2 class="mar_0">
                            Other Metrics
                        </h2>
                    </div>
                    <div class="col-3 pad_0 text-center">
                        <!-- <img width="70px" height="70px" src="../../../../svg/metric.svg"/> -->
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            New sales :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Referral sales :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Renewal sales :
                        </h5>
                    </div>
                    <div class="col-3 pad_0 text-center">
                        <h5 class="mar_0">
                            23
                        </h5>
                        <br/><h5 class="mar_0">
                            23
                        </h5><br/><h5 class="mar_0">
                            23
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            Total number of product :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                           Total sold :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Total Available :
                        </h5>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="mar_0">
                            23
                        </h5>
                        <br/><h5 class="mar_0">
                            23
                        </h5><br/><h5 class="mar_0">
                            23
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            Total Visit :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                           Affidavits :
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                           Approved :
                        </h5>
                    </div>
                    <div class="col-2 text-center">
                        <h5 class="mar_0">
                            23
                        </h5>
                        <br/><h5 class="mar_0">
                            23
                        </h5><br/><h5 class="mar_0">
                            23
                        </h5>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            Altara Credit - Formal
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Altara Credit - Informal
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Altara Pay - Informal
                        </h5>
                        <br/>
                        <h5 class="mar_0">
                            Altara Pay - Formal
                        </h5>
                    </div>
                    <div class="col-3 pad_0 text-center">
                        
                    </div>
                </div>
            </div>
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            Total Repoccesed :
                        </h5>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="mar_0">
                            23
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div class="col-md wCard">
                <div class="row pad_0">
                    <div class="col pad_0_right">
                        <h5 class="mar_0">
                            Disapproved :
                        </h5>                        
                    </div>
                    <div class="col-2 text-center">
                        <h5 class="mar_0">
                            23
                        </h5>
                    </div>
                </div>
            </div>
            
        </div>
        <h3>Recent Sales</h3>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.hCard{
    background: #2975A5;
    mix-blend-mode: normal;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
    color:#fff;
}
.wCard{
    background: #fff;
    mix-blend-mode: normal;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
    color:#000;
}
.pad_0{
    padding: 0 !important;
}
.pad_0_right{
    padding-right: 0 !important;
}
.mar_0{
    margin: 0 !important;
}
</style>